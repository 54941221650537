/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

import { jsx } from '@compiled/react';

import { cssMap } from '@atlaskit/css';
import type { LogoProps } from '@atlaskit/logo';
import { Anchor } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

const navLogoStyles = cssMap({
	root: {
		display: 'flex',
		alignItems: 'center',
		height: '32px',
		borderRadius: token('border.radius.100'),
	},
	icon: {
		display: 'flex',
		'@media (min-width: 64rem)': {
			display: 'none',
		},
	},
	logo: {
		display: 'none',
		'@media (min-width: 64rem)': {
			display: 'flex',
		},
	},
});

/**
 * __Nav logo__
 *
 * The product logo for the top navigation.
 */
export const NavLogo = ({
	href,
	logo: Logo,
	icon: Icon,
	onClick,
	label = 'Home',
}: {
	label?: string;
	href: string;
	logo: (props: LogoProps) => JSX.Element;
	icon: (props: LogoProps) => JSX.Element;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}) => (
	<Anchor aria-label={label} href={href} xcss={navLogoStyles.root} onClick={onClick}>
		<div css={navLogoStyles.icon}>
			<Icon size="small" appearance="brand" label="" />
		</div>
		<div css={navLogoStyles.logo}>
			<Logo size="small" appearance="brand" label="" />
		</div>
	</Anchor>
);
